import { useEffect, useState } from "react";
import SingleProject from "./SingleProject";

const data = [
	{
		href: 'https://edesignparis.fr/',
		src: '/xiaowen.png',
		title: 'E.design',
		category: 'NestJS'
	},
	{
		href: 'https://goflashdeals.com/',
		src: '/gofish.jpg',
		title: 'Goflash Deals',
		category: 'NestJS'
	},
	{
		href: 'http://150.158.92.93:3333/',
		src: '/easyevent.jpg',
		title: 'EasyEvent',
		category: 'Java'
	},
	{
		href: 'https://redux-employee-polls-web.vercel.app/',
		src: '/redux_employee_poll.jpg',
		title: 'Redux Employee Polls',
		category: 'JavaScript'
	},
	{
		href: 'https://photography-portfolio-bego.vercel.app/',
		src: '/Photography Protfolio.jpg',
		title: 'Photography Protfolio',
		category: 'JavaScript'
	},

];

export default function Portfolio(props) {
	const [projects, setProjects] = useState([]);
	const [projectCategory, setProjectCategory] = useState('All');
	useEffect(() => {
		setProjects(data);
		setProjectCategory('All');
	}, []);
	const handleClick = (category) => {
		setProjectCategory(category);
		if (category === 'All') {
			setProjects(data);
		} else {
			setProjects(data.filter(project => project.category === category));
		}
	}
	return (
		<article className={`portfolio  ${props.isClicked ? 'active' : ''}`} data-page="portfolio">

			<header>
				<h2 className="h2 article-title">Portfolio</h2>
			</header>

			<section className="projects">

				<ul className="filter-list">

					<li className="filter-item">
						<button className={`${projectCategory === 'All' ? 'active' : ''}`} onClick={() => handleClick("All")} >All</button>
					</li>

					<li className="filter-item">
						<button className={`${projectCategory === 'Java' ? 'active' : ''}`} onClick={() => handleClick("Java")} >Java</button>
					</li>

					<li className="filter-item">
						<button className={`${projectCategory === 'JavaScript' ? 'active' : ''}`} onClick={() => handleClick("JavaScript")} >JavaScript</button>
					</li>
					<li className="filter-item">
						<button className={`${projectCategory === 'NestJS' ? 'active' : ''}`} onClick={() => handleClick("NestJS")} >NestJS</button>
					</li>

				</ul>


				<ul className="project-list">
					{
						projects.map((project, index) => {
							return <SingleProject key={index} project={project} />
						})
					}

				</ul>

			</section>

		</article>
	)
}