
export default function Contact(props) {
	return (
		<article className={`contact  ${props.isClicked ? 'active' : ''}`} data-page="contact">

			<header>
				<h2 className="h2 article-title">Contact</h2>
			</header>

			<section className="mapbox" data-mapbox>
				<figure>
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d418334.36162644846!2d138.61115984999998!3d-35.000766950000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab735c7c526b33f%3A0x4033654628ec640!2sAdelaide%20SA!5e0!3m2!1sen!2sau!4v1701326847042!5m2!1sen!2sau" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="postion"></iframe>
				</figure>
			</section>

			{/* <section className="contact-form">

				<h3 className="h3 form-title">Contact Form</h3>

				<form action="#" className="form" data-form>

					<div className="input-wrapper">
						<input type="text" name="fullname" className="form-input" placeholder="Full name" required data-form-input />

						<input type="email" name="email" className="form-input" placeholder="Email address" required data-form-input />
					</div>

					<textarea name="message" className="form-input" placeholder="Your Message" required data-form-input></textarea>

					<button className="form-btn" type="submit" disabled data-form-btn>
						<ion-icon name="paper-plane"></ion-icon>
						<span>Send Message</span>
					</button>

				</form>

			</section> */}

		</article>
	)
}