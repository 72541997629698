import './style.css';
function SidebarInfo(props) {
	const { showDetail, setShowDetail } = props.contactShow;
	const handleClick = () => {
		setShowDetail(!showDetail);
	}
	return (
		<div className="sidebar-info">

			<figure className="avatar-box">
				<img src="/avatar.jpg" alt="Elliott Jing" width="80" />
			</figure>

			<div className="info-content">
				<h1 className="name" title="Elliott Jing">Elliott Jing</h1>

				<p className="title">Full-Stack Developer</p>
			</div>

			<button className="info_more-btn" data-sidebar-btn onClick={() => handleClick()}>
				<span>Show Contacts</span>

				<ion-icon name="chevron-down"></ion-icon>
			</button>

		</div>
	)
}

export default SidebarInfo;