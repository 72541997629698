import NavBar from "./NavBar";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import { useEffect, useState } from "react";
import AboutMe from "./AboutMe";

export default function MainContent() {
	const [category, setCategory] = useState("resume");
	useEffect(() => { setCategory("About") }, []);
	return (
		<div className="main-content">
			<NavBar handleClick={{ category, setCategory }} />
			<AboutMe isClicked={category === "About"} />
			<Resume isClicked={category === "Resume"} />
			<Portfolio isClicked={category === "Portfolio"} />
			<Contact isClicked={category === "Contact"} />
		</div>
	)
}