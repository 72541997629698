import './style.css';

export default function SidebarInfoMore() {
	return (
		<div className="sidebar-info_more">

			<div className="separator"></div>

			<ul className="contacts-list">

				<li className="contact-item">

					<div className="icon-box">
						<ion-icon name="mail-outline"></ion-icon>
					</div>

					<div className="contact-info">
						<p className="contact-title">Email</p>

						<a href="mailto:elliott.jing@gmail.com" className="contact-link">elliott.jing@gmail.com</a>
					</div>

				</li>

				{/* <li className="contact-item">

					<div className="icon-box">
						<ion-icon name="phone-portrait-outline"></ion-icon>
					</div>

					<div className="contact-info">
						<p className="contact-title">Phone</p>

						<a href="tel:" className="contact-link"></a>
					</div>

				</li> */}

				<li className="contact-item">

					<div className="icon-box">
						<ion-icon name="location-outline"></ion-icon>
					</div>

					<div className="contact-info">
						<p className="contact-title">Location</p>

						<address>Adelaide, SA, Australia</address>
					</div>

				</li>

			</ul>

			<div className="separator"></div>

			<ul className="social-list">

				<li className="social-item">
					<a href="https://github.com/Elliott-byte" className="social-link">
						<ion-icon name="logo-github"></ion-icon>
					</a>
				</li>

				<li className="social-item">
					<a href="https://www.linkedin.com/in/elliott-jing-2385931b0/" className="social-link">
						<ion-icon name="logo-linkedin"></ion-icon>
					</a>
				</li>


			</ul>

		</div>
	)
}