import SiderBar from "./components/Sidebar";
import MainContent from "./components/MainContent";

function App() {
  return (
    <main>
      <SiderBar />
      <MainContent />
    </main>

  );
}

export default App;
