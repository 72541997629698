import './style.css';
export default function Resume(props) {
	return (
		<article className={`resume  ${props.isClicked ? 'active' : ''} `} data-page="resume">
			<header>
				<h2 className="h2 article-title">Resume</h2>
			</header>

			<section className="timeline">

				<div className="title-wrapper">
					<div className="icon-box">
						<ion-icon name="book-outline"></ion-icon>
					</div>

					<h3 className="h3">Education</h3>
				</div>

				<ol className="timeline-list">

					<li className="timeline-item">

						<h4 className="h4 timeline-item-title">University of Adelaide</h4>

						<span>2022 — 2023</span>

						<p className="timeline-text">
							Master of Science in Computing and Innovation
						</p>

					</li>

					<li className="timeline-item">

						<h4 className="h4 timeline-item-title">University of Liverpool</h4>

						<span>2017 — 2019</span>

						<p className="timeline-text">
							Master of Research in Computer Science
						</p>

					</li>

					<li className="timeline-item">

						<h4 className="h4 timeline-item-title">Shanxi Agricultural University</h4>

						<span>2013 — 2017</span>

						<p className="timeline-text">
							Bachelor of Engineering in Sofware Engineering
						</p>

					</li>

				</ol>

			</section>

			<section className="timeline">

				<div className="title-wrapper">
					<div className="icon-box">
						<ion-icon name="book-outline"></ion-icon>
					</div>

					<h3 className="h3">Experience</h3>
				</div>

				<ol className="timeline-list">

					<li className="timeline-item">

						<h4 className="h4 timeline-item-title">Software Engineer - Capgemini</h4>

						<span>2019 — 2021</span>

						<p className="timeline-text">
							NestJS, Node.js, React, Flask, MySQL, Postgres, AWS, Docker and Kubernetes
						</p>

					</li>

					<li className="timeline-item">

						<h4 className="h4 timeline-item-title">Data Analyst Intern - Bosch Automotive Products</h4>

						<span>2018</span>

						<p className="timeline-text">
							Keras and Deep Leaning
						</p>

					</li>

					<li className="timeline-item">

						<h4 className="h4 timeline-item-title">Research Assistant & Teaching Assistant - Xian Jiatong-Liverpool University</h4>

						<span>2017 — 2019</span>

						<p className="timeline-text">
							TA: Introduction to Java & Database<br />
							RA: Deep Learning, CNN, RNN, LSTM, Video Processing, Image Processing, OpenCV, Keras, Tensorflow

						</p>

					</li>

				</ol>

			</section>

		</article>
	)
}