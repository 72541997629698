import "./style.css";

export default function NavBar(props) {
	const { category, setCategory } = props.handleClick;
	const handleClick = (val) => {
		setCategory(val);
	}

	return (
		<nav className="navbar">

			<ul className="navbar-list">

				<li className="navbar-item">
					<button className={`navbar-link ${category === 'About' ? 'active' : ''} `} data-nav-link onClick={() => handleClick("About")}>About</button>
				</li>

				<li className="navbar-item">
					<button className={`navbar-link ${category === 'Resume' ? 'active' : ''} `} data-nav-link onClick={() => handleClick("Resume")}>Resume</button>
				</li>

				<li className="navbar-item">
					<button className={`navbar-link ${category === 'Portfolio' ? 'active' : ''} `} data-nav-link onClick={() => handleClick("Portfolio")}>Portfolio</button>
				</li>

				<li className="navbar-item">
					<button className={`navbar-link ${category === 'Contact' ? 'active' : ''} `} data-nav-link onClick={() => handleClick("Contact")}>Contact</button>
				</li>

			</ul>

		</nav>
	)
}