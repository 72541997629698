import SidebarInfo from "./SidebarInfo";
import SidebarInfoMore from "./SidebarInfoMore";
import { useState } from 'react';
import './style.css';
function SiderBar() {
	const [showDetail, setShowDetail] = useState(false);
	return (
		<aside className={`sidebar ${showDetail === true ? 'active' : ''}`} data-sidebar>

			<SidebarInfo contactShow={{ showDetail, setShowDetail }} />

			<SidebarInfoMore />

		</aside>
	)
}

export default SiderBar;