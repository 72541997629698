import './style.css';
export default function AboutMe(props) {
	return (
		<article className={`about ${props.isClicked ? 'active' : ''}`} data-page="about">

			<header>
				<h2 className="h2 article-title">About me</h2>
			</header>

			<section className="about-text">
				<p>
					Hello! I'm Elliott Jing, a Software Engineer with a strong academic background from the University of Adelaide and the University of Liverpool.
					My career at Capgemini and Bosch has been marked by developing sophisticated software solutions and data analysis techniques.
					As an AWS Certified Developer and a GovHack 2022 South Australia Winner, I'm proficient in languages like JavaScript, TypeScript, Python,
					and frameworks including NestJS and React.js. My technical expertise is complemented by a passion for creating intuitive, user-friendly digital experiences.
				</p>

				<p>
					Beyond the realm of coding, I have a deep interest in the broader implications of technology in our society. A passionate reader and traveler,
					I am constantly exploring the intersection of technology with culture and ethics. I find joy in understanding different cultures through their literature,
					music, and history, enriching my perspective on the global community. These experiences not only broaden my worldview but also inform my approach to technology and innovation.
				</p>
			</section>

			<section className="service">

				<h3 className="h3 service-title">What i'm doing</h3>

				<ul className="service-list">

					<li className="service-item">

						<div className="service-icon-box">
							<img src="/cloud.png" alt="design icon" width="40" />
						</div>

						<div className="service-content-box">
							<h4 className="h4 service-item-title">Cloud & Backend Development</h4>

							<p className="service-item-text">
								NestJS, Flask, GraphQL, Dokcer, AWS, Azure, , TypeORM, Prisma, Redis, MySQL, ProstgreSQL and so on.
							</p>
						</div>

					</li>

					<li className="service-item">

						<div className="service-icon-box">
							<img src="/web-development.png" alt="Web development icon" width="40" />
						</div>

						<div className="service-content-box">
							<h4 className="h4 service-item-title">Web development</h4>

							<p className="service-item-text">
								React, Redux, Tailwind, JavaScript, TypeScript, Next.js and so on.
							</p>
						</div>

					</li>

				</ul>

			</section>

		</article>

	)
}