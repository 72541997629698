
export default function SingleProject(props) {
	const { project } = props;
	return (
		<li className="project-item  active" data-filter-item >
			<a href={project.href}>

				<figure className="project-img">
					<div className="project-item-icon-box">
						<ion-icon name="eye-outline"></ion-icon>
					</div>

					<img src={project.src} alt="finance" loading="lazy" />
				</figure>

				<h3 className="project-title">{project.title}</h3>

				<p className="project-category">{project.category}</p>

			</a>
		</li>
	)
}